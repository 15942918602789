import React, { useState } from 'react';
import { TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { useClasses } from '../../customHooks';
import SyncAssetTableExpandedRow from './SyncAssetTableExpandedRow';

const styles = {
    overrides: {
        MUIDataTableSelectCell: {
            expandDisabled: {
                visibility: 'hidden',
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "gray"
            }
        },
        MUIDataTableFilter: {
            filterPaper: {
                width: "250px",
            },
        },
        MuiPopover: {
            paper: {
                borderStyle: 'solid',
                borderWidth: 2,
                borderRadius: 10,
                minWidth: '90%'
            }
        }
    },
}

export default function SyncAssetTableExpandable(props) {
    const classes = useClasses(styles);
    const [selections, setSelections] = useState([]);
    const [rowsExpanded, setRowsExpanded] = useState([]);
    const [searchText, setSearchText] = useState("");
    

    const handleSyncAsset = (asset, updateRow) => {
        props.handleSyncAsset(asset).then(updatedAsset => {
            if (updateRow && updatedAsset) {
                updateRow(updatedAsset);
            }
        });
    };

    const handleSelection = (id, action) => {
        setSelections(currentSelections => {
            const index = currentSelections.findIndex(selection => selection.id === id);

            if (index !== -1) {
                return [
                    ...currentSelections.slice(0, index),
                    { id, action },
                    ...currentSelections.slice(index + 1),
                ];
            } else {
                return [...currentSelections, { id, action }];
            }
        });
    };

    const columns = [
        {
            name: "ParentPath",
            label: " ",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span>
                            {props.data[dataIndex].ParentPath}
                        </span>
                    );
                },
                setCellProps: () => ({
                    style: { width: '100%', maxWidth: '100%', borderTop: "1px solid rgba(224, 224, 224, 1)" }
                }),
                searchable: true,
                filter: false,
                sort: true,
                customHeadRender: () => null,
            },
        }
    ]

    const hasSearchTextMatch = (matchTargets) => {
        if (searchText === '') return true;
        const uniqueTargets = [...new Set(matchTargets.filter(item => item !== null))];
        const regex = new RegExp(searchText, 'i');
        return uniqueTargets.some(str => regex.test(str));
    }

    const options = {
        elevation: 5,
        pagination: false,
        filter: false,
        search: true,
        serverSide: false,
        viewColumns: false,
        download: false,
        print: false,
        selectableRows: 'none',
        responsive: 'standard',
        enableNestedDataAccess: ".",
        page: props.tablePageNum,
        onChangePage: (pageNum) => {
            props.setCurrentPage(pageNum);
        },
        rowsPerPage: props.tableRowsPerPage,
        onChangeRowsPerPage: (numberOfRows) => {
            props.setTableRowsPerPage(numberOfRows)
        },
        selectableRowsHideCheckboxes: true,
        searchAlwaysOpen: true,
        searchPlaceholder: 'Search',
        searchText: searchText,
        searchProps: {
            onBlur: (e) => {
                setSearchText(e.target.value);
            },
            onKeyUp: (e) => {
                setSearchText(e.target.value);
            }
        },
        customSearch: (searchQuery, currentRow, columns, allRowsExpanded) => {
            const asset = props.data.find(item => item.ParentPath === currentRow[0]);
            if (!asset) return false;

            return hasSearchTextMatch([asset.ParentPath, asset.ChildrenDescriptions]
                .concat(...asset.Children.map(child => [child.Name, child.Code, child.VibrationObjectName, child.HierarchyPath, child.Description])));
        },
        rowsExpanded: rowsExpanded,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
            const myRowsExpanded = allRowsExpanded.map(item => item.dataIndex);
            setRowsExpanded(myRowsExpanded);
        },
        renderExpandableRow: (rowData, rowMeta) => {
            let childData = props.data[rowMeta.dataIndex]?.Children
                .filter(child => hasSearchTextMatch([child.Name, child.Code, child.VibrationObjectName, child.HierarchyPath, child.Description]));

            //console.log("CHILDDATA: " + JSON.stringify(childData));

            return (
                <TableRow>
                    <SyncAssetTableExpandedRow
                        data={childData}
                        selectOptions={props.options}
                        selections={selections}
                        onSelectionChange={handleSelection}
                        colLength={columns.length}
                        companyInfo={props.companyInfo}
                        onUndoClick={props.handleUndoClick}
                        searchText={props.searchText}
                        onChangeSearchText={props.onChangeSearchText}
                        handleTriggerTestEvent={props.handleTriggerTestEvent}
                        handleSyncAsset={handleSyncAsset}
                        rowNameOverrides={props.rowNameOverrides}
                        toggleAssetNameOverride={props.toggleAssetNameOverride}
                        toggleAssetSaving={props.toggleAssetSaving}
                        assetLoadingStates={props.assetLoadingStates}
                    />
                </TableRow>
            );
        },
    };

    const components = {
        ExpandButton: function (props) {
            return <ExpandButton {...props} />;
        },
    };

    return (
        <MUIDataTable
            title={props.title}
            data={props.data}
            columns={columns}
            options={options}
            className={classes.overrides}
            components={components}
        />
    );
}
